// File: src/components/Header.js

import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Divider, TextField, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Header.css'; // Import the CSS file for header styles

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false); // State to track scroll position
  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen is 600px or smaller

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log('Search Term:', searchTerm);
    setSearchTerm(''); // Optionally clear the search term after submission
  };

  // Effect to change header background color on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <AppBar 
        position="fixed" 
        style={{ 
          backgroundColor: isScrolled ? '#333333' : '#E3F2FD', // Dark grey when scrolled, light blue at top
          color: isScrolled ? '#ffffff' : '#000000', // White text when scrolled, black at top
          boxShadow: 'none',
          padding: '10px 0'
        }}
      >
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" style={{ fontWeight: 'bold', color: isScrolled ? '#ffffff' : '#000000', flexGrow: 1 }}>
            Huromata
          </Typography>

          {/* Search Bar - Display only on larger screens */}
          {!isMobile && (
            <form onSubmit={handleSearchSubmit} style={{ marginBottom: '10px', flexGrow: 1, maxWidth: '300px' }}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  style: {
                    backgroundColor: isScrolled ? '#777' : '#ffffff', // Grey when scrolled, white at top
                    color: '#000000', // Black text in search bar
                  },
                }}
                fullWidth
              />
            </form>
          )}

          {/* Navigation Links */}
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
            {['/', '/tools', '/services', '/workshops', '/contact'].map((path, index) => (
              <NavLink 
                key={index} 
                to={path} 
                className="nav-link" 
                style={{ 
                  color: isScrolled ? '#ffffff' : '#000000', 
                  padding: '0 15px', 
                  textDecoration: 'none', // Remove underline
                }}
                activeStyle={{
                  fontWeight: 'bold', // Optional: Highlight active link
                  textDecoration: 'underline',
                }}
              >
                {path === '/' ? 'Home' : path.slice(1).charAt(0).toUpperCase() + path.slice(2)} {/* Capitalize the link text */}
              </NavLink>
            ))}
          </div>
        </Toolbar>
      </AppBar>
      
      <Divider style={{ backgroundColor: '#333', height: '1px' }} /> {/* Thicker divider */}

      {/* Mobile View (Navigation links shown directly) */}
      {isMobile && (
        <Drawer anchor="right" open={true} onClose={() => {}}>
          <List>
            {['Home', 'Tools', 'Services', 'Workshops', 'Contact'].map((text, index) => (
              <ListItem button component={NavLink} to={index === 0 ? '/' : `/${text.toLowerCase()}`} key={text}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
    </div>
  );
};

export default Header;
