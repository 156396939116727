// src/pages/coursesData/RoboticsCourse.js

const roboticsCourse = {
  id: 3,
  title: 'Robotics Basics to Autonomous Robots',
  description: 'Dive into robotics and progress to autonomous systems, gaining hands-on skills with industry experts.',
  duration: '10-20 days based on requirements',
  image: '/images/robotics_basics.jpg',
  category: 'Robotics',
  level: 'Advanced',
};

export default roboticsCourse;
