// src/pages/coursesData/DroneCourse.js

const droneCourse = {
  id: 1,
  title: 'Drone Basics to Autonomous Drone',
  description: 'Learn the essentials of drone technology and progress to autonomous drone systems with expert-led training.',
  duration: '10-20 days based on requirements',
  image: '/images/drone_basics.jpg',
  category: 'Drones',
  level: 'Beginner',
};

export default droneCourse;
