// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define a custom theme with monochromatic colors
const theme = createTheme({
  palette: {
    mode: 'dark', // Enables dark mode
    primary: {
      main: '#00868b', // Teal tone as primary color
      contrastText: '#e0e0e0', // Light gray text for better readability on buttons, etc.
    },
    secondary: {
      main: '#008080', // Slightly darker variation for secondary
      contrastText: '#e0e0e0',
    },
    background: {
      default: '#121212', // Dark gray background for the entire app
      paper: '#1c1c1c', // Slightly lighter gray for surfaces like cards
    },
    text: {
      primary: '#e0e0e0', // Light gray for primary text
      secondary: '#b0b0b0', // Slightly darker gray for secondary text
      disabled: '#707070', // Even darker gray for disabled text
    },
    action: {
      active: '#00868b', // Accent color for active elements
    },
    divider: '#303030', // Subtle gray divider lines
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 700,
      color: '#e0e0e0', // Consistent light gray for headings
    },
    h2: {
      fontWeight: 600,
      color: '#e0e0e0',
    },
    h3: {
      fontWeight: 600,
      color: '#e0e0e0',
    },
    h4: {
      fontWeight: 600,
      color: '#e0e0e0',
    },
    h5: {
      fontWeight: 500,
      color: '#e0e0e0',
    },
    h6: {
      fontWeight: 500,
      color: '#e0e0e0',
    },
    body1: {
      color: '#b0b0b0', // Slightly dimmed for body text
    },
    body2: {
      color: '#909090', // Even dimmer for secondary text
    },
    button: {
      color: '#e0e0e0', // Ensure high contrast for buttons
    },
    caption: {
      color: '#808080', // Muted gray for captions
    },
    overline: {
      color: '#707070', // Very muted gray for overlines
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1c', // Card surfaces aligned with paper background
          color: '#e0e0e0', // Ensure text is readable on dark card background
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#e0e0e0', // Default text color set to primary light gray
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#00868b', // Consistent button color
          color: '#e0e0e0', // Text color on button
          '&:hover': {
            backgroundColor: '#006f6f', // Darken slightly on hover for feedback
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1c', // Keep paper background consistent with card
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1c1c1c', // Dark app bar to blend with theme
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#303030', // Subtle dividers that match dark theme
        },
      },
    },
  },
});

export default theme;
