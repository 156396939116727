// Import necessary dependencies
import React, { useState } from 'react';
import { Typography, Box, Grid, Card, CardContent, Button, IconButton, Chip, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';

// Import individual course data files
import droneCourse from './coursesData/DroneCourse';
import printingCourse from './coursesData/PrintingCourse';
import roboticsCourse from './coursesData/RoboticsCourse';
import cybersecurityCourse from './coursesData/CybersecurityCourse';

// Combine imported courses into an array
const courses = [droneCourse, printingCourse, roboticsCourse, cybersecurityCourse];

// Define available course categories for filtering
const categories = [...new Set(courses.map((course) => course.category))];

const Courses = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Filter courses based on selected category
  const filteredCourses = selectedCategory === 'All' 
    ? courses 
    : courses.filter((course) => course.category === selectedCategory);

  return (
    <Box sx={{ padding: '40px', background: 'linear-gradient(135deg, #f0f4ff, #e1e8f0)', minHeight: '100vh' }}>
      {/* Header */}
      <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#2a3c5f', mb: 5, textAlign: 'center' }}>
        Our Expert-Led Courses
      </Typography>

      {/* Filter Options */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Tooltip title="Filter by Category">
          <IconButton color="primary">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Button 
          onClick={() => setSelectedCategory('All')}
          sx={{ textTransform: 'capitalize', ml: 2 }}
          color={selectedCategory === 'All' ? 'primary' : 'inherit'}
        >
          All
        </Button>
        {categories.map((category) => (
          <Button 
            key={category}
            onClick={() => setSelectedCategory(category)}
            sx={{ textTransform: 'capitalize', ml: 2 }}
            color={selectedCategory === category ? 'primary' : 'inherit'}
          >
            {category}
          </Button>
        ))}
      </Box>

      {/* Course Cards */}
      <Grid container spacing={4}>
        {filteredCourses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <NavLink to={`/courses/${course.id}`} style={{ textDecoration: 'none' }}>
              <Card 
                sx={{ 
                  borderRadius: '15px', 
                  boxShadow: '0 8px 24px rgba(0,0,0,0.1)', 
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  backgroundColor: '#ffffff',
                  '&:hover': { transform: 'scale(1.05)', boxShadow: '0 12px 32px rgba(0,0,0,0.15)' }
                }}
              >
                <Box 
                  sx={{ 
                    position: 'relative', 
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${course.image})`, 
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center', 
                    height: '200px',
                  }}
                >
                  <Chip
                    label={course.category}
                    sx={{ position: 'absolute', top: 10, left: 10, backgroundColor: '#465a7a', color: '#ffffff', fontWeight: 'bold' }}
                  />
                  <Chip
                    label={course.level}
                    sx={{ position: 'absolute', top: 10, right: 10, backgroundColor: '#3b486d', color: '#ffffff', fontWeight: 'bold' }}
                  />
                </Box>
                
                <CardContent sx={{ textAlign: 'center', padding: '20px' }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2a3c5f', mb: 1 }}>
                    {course.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#607196', mt: 1 }}>
                    {course.description}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#465a7a', mt: 2, fontWeight: 'bold' }}>
                    Duration: {course.duration}
                  </Typography>
                  <Button 
                    variant="contained" 
                    sx={{ 
                      marginTop: '20px', 
                      backgroundColor: '#3b486d', 
                      color: '#ffffff', 
                      borderRadius: '20px',
                      padding: '8px 20px',
                      '&:hover': { backgroundColor: '#2a3c5f' }
                    }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </NavLink>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
        <Button variant="outlined" startIcon={<DownloadIcon />} sx={{ color: '#3b486d', borderColor: '#3b486d', '&:hover': { backgroundColor: '#f0f4ff', borderColor: '#2a3c5f' } }}>
          Download Brochure
        </Button>
      </Box>
    </Box>
  );
};

export default Courses;
