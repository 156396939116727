// src/pages/coursesData/PrintingCourse.js

const printingCourse = {
  id: 2,
  title: '3D Printing Master',
  description: 'Become proficient in 3D printing, from the fundamentals to advanced printing techniques and materials.',
  duration: '10-20 days based on requirements',
  image: '/images/3d_printing_master.jpg',
  category: '3D Printing',
  level: 'Intermediate',
};

export default printingCourse;
