// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';  // Updated for React 18
import App from './App';  // Import the main App component
import './index.css';    // Import global CSS styles

// Create a root for rendering in React 18+
const root = ReactDOM.createRoot(document.getElementById('root'));  // Get the DOM element with id 'root'

// Render the React app using createRoot
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);