// src/pages/coursesData/CybersecurityCourse.js

const cybersecurityCourse = {
  id: 4,
  title: 'Cybersecurity Basics',
  description: 'Understand the fundamentals of cybersecurity, including network protection, threat analysis, and best practices.',
  duration: '10-20 days based on requirements',
  image: '/images/cybersecurity_basics.jpg',
  category: 'Cybersecurity',
  level: 'Beginner',
};

export default cybersecurityCourse;
