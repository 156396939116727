// src/components/Footer.js
import React from 'react';
import { Container, Typography, Grid, Link, IconButton, Box, useMediaQuery } from '@mui/material';
import { LinkedIn, Instagram, WhatsApp } from '@mui/icons-material';  // Import relevant icons

import logo from '../assets/images/homepage/logo.jpg';  // Importing the logo

const Footer = () => {
  // Media queries to detect screen size
  const isSmallScreen = useMediaQuery('(max-width:600px)');  // For small screens (phones)
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');  // For medium screens (tablets)
  const isLargeScreen = useMediaQuery('(min-width:961px)');  // For large screens (desktops)

  // Calculate logo size based on screen size
  const logoSize = isSmallScreen ? '50px' : isMediumScreen ? '70px' : '100px';

  return (
    <>
      {/* Black line above footer */}
      <div style={{ borderTop: '2px solid #000000' }}></div>
      <footer style={{ background: 'linear-gradient(45deg, #333333, #555555)', color: '#E0E0E0', padding: '40px 0' }}>
        <Container>
          <Grid container spacing={4}>
            {/* Company Info */}
            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                {/* Logo with dynamic size */}
                <img src={logo} alt="Huromata Logo" style={{ width: logoSize, marginRight: '16px' }} /> {/* Responsive logo size */}
                <Typography variant="h6" gutterBottom style={{ fontWeight: 600, fontSize: '1.25rem', color: '#009688' }}>
                  Huromata
                </Typography>
              </Box>
              <Typography variant="body2" style={{ fontSize: '0.875rem', lineHeight: 1.6, color: '#E0E0E0' }}>
                Transforming Ideas into Market-Ready Solutions. We specialize in providing innovative solutions tailored to your business needs.
              </Typography>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom style={{ fontWeight: 600, fontSize: '1.25rem', color: '#009688' }}>
                Quick Links
              </Typography>
              <Link href="/" color="inherit" style={{ display: 'block', marginBottom: '8px', fontSize: '0.875rem', textDecoration: 'none', transition: 'color 0.3s' }} 
                onMouseEnter={e => e.target.style.color = '#FF6600'} 
                onMouseLeave={e => e.target.style.color = '#E0E0E0'}>
                Home
              </Link>
              <Link href="/about" color="inherit" style={{ display: 'block', marginBottom: '8px', fontSize: '0.875rem', textDecoration: 'none', transition: 'color 0.3s' }} 
                onMouseEnter={e => e.target.style.color = '#FF6600'} 
                onMouseLeave={e => e.target.style.color = '#E0E0E0'}>
                About Us
              </Link>
              <Link href="/services" color="inherit" style={{ display: 'block', marginBottom: '8px', fontSize: '0.875rem', textDecoration: 'none', transition: 'color 0.3s' }} 
                onMouseEnter={e => e.target.style.color = '#FF6600'} 
                onMouseLeave={e => e.target.style.color = '#E0E0E0'}>
                Services
              </Link>
              <Link href="/contact" color="inherit" style={{ display: 'block', marginBottom: '8px', fontSize: '0.875rem', textDecoration: 'none', transition: 'color 0.3s' }} 
                onMouseEnter={e => e.target.style.color = '#FF6600'} 
                onMouseLeave={e => e.target.style.color = '#E0E0E0'}>
                Contact
              </Link>
            </Grid>

            {/* Social Media Links */}
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" gutterBottom style={{ fontWeight: 600, fontSize: '1.25rem', color: '#009688' }}>
                Follow Us
              </Typography>
              <div style={{ display: 'flex', gap: '12px' }}>
                {/* Updated LinkedIn URL */}
                <IconButton color="inherit" href="https://www.linkedin.com/in/aswath-huromata-4622b5247/" target="_blank" rel="noopener noreferrer">
                  <LinkedIn style={{ fontSize: '1.5rem', transition: 'transform 0.3s', color: '#E0E0E0' }} 
                    onMouseEnter={e => e.target.style.transform = 'scale(1.2)'} 
                    onMouseLeave={e => e.target.style.transform = 'scale(1)'} />
                </IconButton>
                {/* WhatsApp */}
                <IconButton color="inherit" href="https://wa.me/9626223100" target="_blank" rel="noopener noreferrer">
                  <WhatsApp style={{ fontSize: '1.5rem', transition: 'transform 0.3s', color: '#E0E0E0' }} 
                    onMouseEnter={e => e.target.style.transform = 'scale(1.2)'} 
                    onMouseLeave={e => e.target.style.transform = 'scale(1)'} />
                </IconButton>
                {/* Instagram */}
                <IconButton color="inherit" href="https://www.instagram.com/huromata/" target="_blank" rel="noopener noreferrer">
                  <Instagram style={{ fontSize: '1.5rem', transition: 'transform 0.3s', color: '#E0E0E0' }} 
                    onMouseEnter={e => e.target.style.transform = 'scale(1.2)'} 
                    onMouseLeave={e => e.target.style.transform = 'scale(1)'} />
                </IconButton>
              </div>
            </Grid>
          </Grid>

          {/* Contact Info */}
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" align="center" style={{ fontSize: '0.875rem', color: '#E0E0E0' }}>
                <a href="mailto:teamhuromata@gmail.com" style={{ textDecoration: 'none', color: '#009688' }}>
                  Email: teamhuromata@gmail.com
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" align="center" style={{ fontSize: '0.875rem', color: '#E0E0E0' }}>
                <a href="tel:+9626223100" style={{ textDecoration: 'none', color: '#009688' }}>
                  Phone: +9626223100
                </a>
              </Typography>
            </Grid>
          </Grid>

          {/* Address */}
          <Grid container spacing={2} style={{ marginTop: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="body2" align="center" style={{ fontSize: '0.875rem', color: '#E0E0E0' }}>
                <strong>Address:</strong> No.10, near Kalyana Mandapam, Kaleeswara Nagar, Kattoor, Ram Nagar, Coimbatore, Tamil Nadu 641009
              </Typography>
            </Grid>
          </Grid>

          {/* Copyright Section */}
          <Typography variant="body2" align="center" style={{ marginTop: '20px', fontSize: '0.75rem', color: '#E0E0E0' }}>
            © {new Date().getFullYear()} Huromata. All rights reserved.
          </Typography>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
