// src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Courses from './pages/Courses';
import CourseDetail from './pages/CourseDetail';

/* Slick carousel styles */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Lazy load individual course detail pages
const DroneCourse = lazy(() => import('./pages/coursesData/DroneCourse'));
const PrintingCourse = lazy(() => import('./pages/coursesData/PrintingCourse'));
const RoboticsCourse = lazy(() => import('./pages/coursesData/RoboticsCourse'));
const CybersecurityCourse = lazy(() => import('./pages/coursesData/CybersecurityCourse'));

// Other lazy-loaded pages
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/services/Services'));
const Tools = lazy(() => import('./components/Tools'));
const Store = lazy(() => import('./pages/store/store'));
const Events = lazy(() => import('./components/Events'));
const Workshops = lazy(() => import('./components/Workshops'));
const Contact = lazy(() => import('./components/Contact'));
const Careers = lazy(() => import('./components/Careers'));
const Privacy = lazy(() => import('./components/Privacy'));
const Testimonials = lazy(() => import('./components/Testimonials'));
const CaseStudies = lazy(() => import('./components/CaseStudies'));
const Partners = lazy(() => import('./components/Partners'));
const Resources = lazy(() => import('./components/Resources'));
const Portfolio = lazy(() => import('./components/Portfolio'));
const Community = lazy(() => import('./components/Community'));
const Support = lazy(() => import('./components/Support'));
const Sustainability = lazy(() => import('./components/Sustainability'));
const InnovationLab = lazy(() => import('./components/InnovationLab'));
const Projects = lazy(() => import('./pages/Project/Projects'));
const STLSlicer = lazy(() => import('./pages/Tools/STLSlicer'));

// Lazy load services pages
const Faculty = lazy(() => import('./pages/services/Faculty'));
const Institutions = lazy(() => import('./pages/services/Institutions'));
const Startups = lazy(() => import('./pages/services/Startups'));
const ThreeDPrintingServices = lazy(() => import('./pages/services/3d-printing-services'));

// Lazy load calculators
const KlipperCalculator = lazy(() => import('./pages/Tools/KlipperCalculator'));
const GSTCalculator = lazy(() => import('./pages/Tools/GSTCalculator'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header />
        <main style={{ marginTop: '64px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Home and core pages */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/services" element={<Services />} />
              <Route path="/courses" element={<Courses />} />

              {/* Individual Course Detail Pages */}
              <Route path="/courses/:id" element={<CourseDetail />} />
              {/* Routes for each individual course */}
              <Route path="/courses/1" element={<DroneCourse />} />
              <Route path="/courses/2" element={<PrintingCourse />} />
              <Route path="/courses/3" element={<RoboticsCourse />} />
              <Route path="/courses/4" element={<CybersecurityCourse />} />

              {/* Services Pages */}
              <Route path="/services/institutions" element={<Institutions />} />
              <Route path="/services/faculty" element={<Faculty />} />
              <Route path="/services/startups" element={<Startups />} />
              <Route path="/services/3d-printing" element={<ThreeDPrintingServices />} />
              
              {/* Tools Pages */}
              <Route path="/tools" element={<Tools />} />
              <Route path="/stl-slicer" element={<STLSlicer />} />
              <Route path="/klipper-calculator" element={<KlipperCalculator />} />
              <Route path="/gst-calculator" element={<GSTCalculator />} />

              {/* Store Page */}
              <Route path="/store" element={<Store />} />

              {/* Other Pages */}
              <Route path="/events" element={<Events />} />
              <Route path="/workshops" element={<Workshops />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/case-studies" element={<CaseStudies />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/community" element={<Community />} />
              <Route path="/support" element={<Support />} />
              <Route path="/sustainability" element={<Sustainability />} />
              <Route path="/innovation-lab" element={<InnovationLab />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
