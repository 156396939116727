// src/pages/CourseDetail.js

import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Chip, Button } from '@mui/material';

// Import all course data files
import droneCourse from './coursesData/DroneCourse';
import printingCourse from './coursesData/PrintingCourse';
import roboticsCourse from './coursesData/RoboticsCourse';
import cybersecurityCourse from './coursesData/CybersecurityCourse';

// Map each course by ID for easy lookup
const courseData = {
  1: droneCourse,
  2: printingCourse,
  3: roboticsCourse,
  4: cybersecurityCourse,
};

const CourseDetail = () => {
  const { id } = useParams();
  const course = courseData[id];

  // Display a message if the course is not found
  if (!course) {
    return <Typography variant="h4" color="error">Course not found</Typography>;
  }

  return (
    <Box sx={{ padding: '20px' }}>
      <Card sx={{ maxWidth: 800, margin: '0 auto', boxShadow: 3 }}>
        {/* Course Image */}
        <Box
          sx={{
            height: '300px',
            backgroundImage: `url(${course.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <CardContent>
          {/* Course Title */}
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            {course.title}
          </Typography>
          
          {/* Category and Level */}
          <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
            <Chip label={course.category} color="primary" />
            <Chip label={course.level} color="secondary" />
          </Box>
          
          {/* Description */}
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {course.description}
          </Typography>
          
          {/* Duration */}
          <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'gray' }}>
            Duration: {course.duration}
          </Typography>
          
          {/* Enroll Button */}
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 3 }}
          >
            Enroll Now
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CourseDetail;
